import "./scss/main.scss";
import "bootstrap";

// Form
const elForm = document.getElementById("form");
const elAlert = document.getElementById("alert");
const elBtn = document.querySelector("button#submit");

// Send email
elForm.addEventListener("submit", function (e) {
  e.preventDefault();
  elAlert.classList.remove("d-none"); // Spinner on
  elAlert.classList.add("d-flex"); // Spinner on

  grecaptcha.ready(function () {
    grecaptcha.execute("6LcK3FIaAAAAANm9dlQKFkgM8I_W0v3zjQKJ3gxf", { action: "submit" }).then(function (token) {
      // Add your logic to submit to your backend server here.

      const formData = new FormData(elForm);
      const searchParams = new URLSearchParams();

      for (const pair of formData) {
        searchParams.append(pair[0], pair[1]);
      }

      searchParams.append("g-recaptcha-response", token);

      fetch("form-send.php", {
        body: searchParams,
        method: "POST",
      })
        .then((response) => {
          return response.text();
        })
        .then((text) => {
          notification("success");
        })
        .catch((err) => {
          notification("fail", err);
        });
    });
  }); // grecaptcha
});

const notification = (state, err = false) => {
  if (state === "success") {
    elAlert.innerHTML = "Mensagem enviada com sucesso!";
    elAlert.classList.add("alert-success");
    elBtn.setAttribute("disabled", "true");
  } else {
    elAlert.innerHTML = `Erro ao tentar enviar ${err}`;
    elAlert.classList.add("alert-danger");
  }
  elAlert.classList.remove("alert-dark");
  setTimeout(() => {
    elAlert.classList.remove("alert-success", "alert-danger", "alert-dark", "d-flex");
    elAlert.classList.add("d-none");
  }, 5000);
};
